export default (params, paramsController) => {
	const keys = Object.keys(params)
	let paramsArray = []

	for (let key of keys) {
		if (params[key] && paramsController[key]) {
			if (['from', 'to'].includes(key))
				if (window.location.pathname === '/dashboard' || window.location.pathname === '/goals/overview' || window.location.pathname === '/performance/tracker' || window.location.pathname === '/questionnaires/data/action' || window.location.pathname === '/questionnaires/branding' || window.location.pathname === '/resales/list') {
					paramsArray.push(`${paramsController[key]}=${params[key]}`)
				} else {
					paramsArray.push(`${paramsController[key]}=${params[key].toLocaleDateString('pt-br').replaceAll('/', '-')}`)
				}
			else
				paramsArray.push(`${paramsController[key]}=${params[key]}`)
		}
	}

	return paramsArray.join('&')
}