import React from 'react'
import PageTitle from '../../components/PageTitle';
import {
	Row,
	Card,
	CardBody,
	Col,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from 'reactstrap'
import classNames from 'classnames';
import paginationFactory, { SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import { withTranslation } from 'react-i18next'
import DataSpinner from '../../components/DataLoader';
import ProfitabilityService from '../../services/ProfitabilityService';
import sizePerPageItens from '../../constants/sizePerPageItens';
import BootstrapTable from 'react-bootstrap-table-next';
import RenderingData from '../../constants/RenderingData';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import formatDate from '../../functions/formatDate';


const TableWithSearch = ({ data, columns, fetchedData, onTableChange, filterOptions }) => {
	const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
		<React.Fragment>
			<label className="d-inline mr-1">Mostrar</label>
			<UncontrolledDropdown className="d-inline">
				<DropdownToggle caret tag="button" type="button" className="btn btn-outline-secondary btn-sm">
					{currSizePerPage}
				</DropdownToggle>
				<DropdownMenu>
					{options.map((option, idx) => (
						<DropdownItem
							key={idx}
							type="button"
							className={classNames({ active: currSizePerPage + '' === option.page + '' })}
							onClick={() => onSizePerPageChange(option.text)}>
							{option.text}
						</DropdownItem>
					))}
				</DropdownMenu>
			</UncontrolledDropdown>
			<label className="d-inline ml-1">Clientes</label>
		</React.Fragment>
	);
	const paginationOptions = {
		sizePerPage: filterOptions.sizePerPage,
		page: filterOptions.pageIndex,
		totalSize: filterOptions.totalSize,
		sizePerPageList: sizePerPageItens,
		showTotal: true,
		onSizePerPageChange: (data) => onTableChange('pagination', { sizePerPage: data }),
		paginationTotalRenderer: (from, to, size) => (
			<span>Mostrando {from}-{to} de {size}</span>
		),
		sizePerPageRenderer: sizePerPageRenderer,
		lastPageTitle: "Última página",
		nextPageTitle: "Próxima página",
		prePageTitle: "Página anterior",
		firstPageTitle: "Primeira página"
	}
	const { SearchBar } = Search;

	return (
		<React.Fragment>
			<ToolkitProvider
				bootstrap4
				keyField="id"
				data={data}
				columns={columns}
				search
			>
				{props => (
					<React.Fragment>
						<Row>
							<Col>
								<SizePerPageDropdownStandalone {...paginationOptions} />
							</Col>

							{/* <Col id="search-bar" className="text-right">
								<SearchBar placeholder="Digite pelo menos 3 caracteres" {...props.searchProps} />
							</Col> */}
						</Row>
						<BootstrapTable
							{...props.baseProps}
							remote
							noDataIndication={() => !fetchedData && <RenderingData />}
							striped
							onTableChange={onTableChange}
							bordered={false}
							pagination={paginationFactory({ ...paginationOptions, hideSizePerPage: true })}
							wrapperClasses="table-responsive"
						/>
					</React.Fragment>
				)}
			</ToolkitProvider>
		</React.Fragment>
	);
};

class Details extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isLoading: false,
			detailsTableController: {
				search: undefined,
				sortField: undefined,
				sortOrder: undefined,
				sizePerPage: sizePerPageItens[0].value,
				pageIndex: 1,
				totalSize: undefined,
			},
			details: undefined
		}
		this.headerFixed = false

		this.onTableChange = this.onTableChange.bind(this)
		this.resetDetails = this.resetDetails.bind(this)
		this.tglLoading = this.tglLoading.bind(this)
	}

	async componentDidMount() {
		this.fetchDetails(this.state.detailsTableController)
	}

	resetDetails() {
		this.setState({
			details: undefined
		})

	}

	tglLoading() {
		this.setState({ isLoading: !this.state.isLoading })
	}

	fetchDetails(filter) {
		this.tglLoading()
		if (this.state.details)
			this.resetDetails()
		const response = ProfitabilityService.getImportDetails(this.props.match.params.id, filter)
		response.then(res => {
			this.tglLoading()
			let { detailsTableController } = this.state

			detailsTableController.pageIndex = res.data.current_page
			detailsTableController.sizePerPage = res.data.per_page
			detailsTableController.totalSize = res.data.total
			this.setState({
				details: res.data.data,
				detailsTableController
			})
		})
	}

	async onTableChange(type, newState) {
		let currentFilterOptions = this.state.detailsTableController

		if (type === 'search') {
			if (newState.searchText.length > 2 || newState.searchText.length === 0) {
				currentFilterOptions.search = newState.searchText
				this.fetchDetails({ ...currentFilterOptions, pageIndex: newState.page })
			}
		}
		else {
			if (type === 'sort') {
				if (newState.sortField === 'mainSegment')
					newState.sortField = 'segments'
				const realField = newState.sortField.includes('.') ? newState.sortField.substr(newState.sortField.indexOf('.') + 1) : newState.sortField
				currentFilterOptions.sortField = realField
				currentFilterOptions.sortOrder = newState.sortOrder
			}
			else if (type === 'pagination')
				currentFilterOptions.sizePerPage = newState.sizePerPage

			this.fetchDetails({ ...currentFilterOptions, pageIndex: newState.page })
		}
	}

	dateCell(cell, row, rowIndex, extraData) {
		const formatedValue = formatDate(cell)
		return (
			<span className='clamp-line' title={formatedValue}>{formatedValue}</span>
		)
	}

	priceCell(cell, row) {
		const formatedValue = cell?.toLocaleString('en-US', {
			style: 'currency',
			currency: 'USD',
		})
		return (
			<span className='clamp-line' title={formatedValue}>{formatedValue}</span>
		)
	}

	clampCell(cell, row) {
		return (
			<span className='clamp-line' title={cell}>
				{cell}
			</span>
		)
	}

	columns = [
		{
			dataField: 'account_name',
			text: 'Nome',
			sort: true,
			formatter: this.clampCell
		},
		{
			dataField: 'business_segment',
			text: 'Segmento',
			sort: true,
			formatter: this.clampCell
		},
		{
			dataField: 'created_at',
			text: 'Criação',
			sort: true,
			formatter: this.dateCell
		},
		{
			dataField: 'raw_marketing_name',
			text: 'Raw marketing name',
			sort: true,
			formatter: this.clampCell
		},
		{
			dataField: 'stmodel',
			text: 'stmodel',
			sort: true,
			formatter: this.clampCell
		},
		{
			dataField: 'price_total',
			text: 'Preço total',
			sort: true,
			formatter: this.priceCell
		},
		{
			dataField: 'price_unit',
			text: 'Preço por unidade',
			sort: true,
			formatter: this.priceCell
		},
	];

	render() {
		return (
			<>
				<DataSpinner render={this.state.isLoading} />
				<Row>
					<Col>
						<div className={
							`page-title-box ${this.headerFixed ?
								'fixed-page-header'
								:
								''}`
						}>
							<PageTitle
								colClass="p-0"
								customClass="nav-to-right"
								breadCrumbItems={[
									{ label: 'Rentabilidade', path: '/profitability/products', active: false },
									{ label: 'Importações', path: '/profitability/imports', active: false },
									{ label: 'Detalhes da importação', path: `/profitability/details/${this.props.match.params.id}`, active: true },
								]}
								title='Detalhes da importação'
							/>
						</div>
						{this.headerFixed &&
							<div className="fixed-page-header-ghost"></div>
						}
					</Col>
				</Row>

				<Row>
					<Col>
						<Card className=''>
							<CardBody>
								<TableWithSearch
									data={this.state.details || []}
									columns={this.columns}
									fetchedData={this.state.details !== undefined}
									onTableChange={this.onTableChange}
									filterOptions={this.state.detailsTableController}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</>
		)
	}
}

export default withTranslation()(Details)