// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import PropTypes from 'prop-types';


/**
 * PageTitle
 */
const PageTitle = (props) => {
	return (
		<Row className="w-200">
			<Col className={`${props.colClass}`}>
				<div className={"page-title-box " + props.customClass}>
					<div className="page-title-right">
						{!props.hideBreadCrumb &&
							<Breadcrumb>
								<BreadcrumbItem>
									<Link to="/">Seagate Central</Link>
								</BreadcrumbItem>
								{props.breadCrumbItems.map((item, index) => {
									return item.active ? (
										<BreadcrumbItem active key={index}>
											{item.label}
										</BreadcrumbItem>
									) : (
										<BreadcrumbItem key={index}>
											<Link to={item.path}>{item.label}</Link>
										</BreadcrumbItem>
									);
								})}
							</Breadcrumb>
						}
					</div>
					<h4 className="page-title">{props.title}</h4>
				</div>
			</Col>
		</Row>
	);
};

export default PageTitle;
