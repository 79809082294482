import axios from 'axios'
import allCountries from '../constants/allCountriesList'
import Constants from '../services/Constants'
import apiCall from './singleApiCallModel'
import removeZeros from '../functions/removeZeros'
import getCountry from '../functions/getCountry'
import getParamsUrl from '../functions/getParamsUrl'
import { controllers } from 'chart.js'
export default class Revendas {

  static getUrlParams(filterOptions) {
    filterOptions = removeZeros(filterOptions)
    const NoCountries = !filterOptions.country || filterOptions.country === allCountries
    filterOptions.country = NoCountries ? allCountries : filterOptions.country
    const control = {
      id: 'consultant_id',
      country: 'countries',
      pageIndex: 'page',
      tier: 'tier',
      segment: 'segment',
      sortField: 'order',
      sortOrder: 'order_by',
      search: 'search',
      sizePerPage: 'limit',
      is_new_reseller: 'is_new_reseller',
      to: 'to',
      from: 'from',
      statusTwo: 'status'
    }
    console.log(getParamsUrl(filterOptions, control))
    console.log('=========')
    return getParamsUrl(filterOptions, control)
  }

  static async getResellersData(filterOptions) {
    return await apiCall(`${Constants.getApiUrl()}/v1/admin/resellers?${this.getUrlParams(filterOptions)}`, 'get')
  }

  static async delReseller(id) {
    return await apiCall(`${Constants.getApiUrl()}/v1/reseller/${id}`, 'del')
  }

  static async actvReseller(id) {
    return await apiCall(`${Constants.getApiUrl()}/v1/admin/resellers/activate/${id}`, 'get')
  }

  static async newReseller(revenda, contactsData) {
    const data = await apiCall(`${Constants.getApiUrl()}/v1/reseller`, 'post', revenda)
    if (data.error)
      return data
    if (contactsData.length > 0) {
      await this.newResellerContact(data?.reseller?.id, contactsData);
    }
    return data
  }

  static async newResellerV2(revenda) {
    const data = await apiCall(`${Constants.getApiUrl()}/v1/new-reseller`, 'post', revenda)
    return data
  }

  static async updateReseller(id, atualizacao, contactStack) {
    const data = await apiCall(`${Constants.getApiUrl()}/v1/reseller/${id}`, 'put', atualizacao)
    if (data.error) {
      return data
    }
    // await this.updateContacts(contactStack, data.data.reseller);

    await axios.post(`${Constants.getApiUrl()}/v1/reseller/contact`, {
      reseller_id: id,
      contacts: contactStack
    }, Constants.getHeaderConfig()).then(response => {
      if (response.status === 200)
        window.location.reload()
    })

    return data
  }

  static async updateContacts(contacts, resellerData) {
    const urls = contacts.map(d => {
      return `${Constants.getApiUrl()}/v1/${d.firstPrefix}/${d.secondPrefix}/${d.secondId}`;
    });
    const models = contacts.map(d => {
      d.valueToUpdate[d.firstPrefix] = d.firstId
      d.valueToUpdate.consultant = resellerData.consultant_id
      return d.valueToUpdate
    });
    const requests = models.map((model, i) => {
      return axios.put(urls[i], model, Constants.getHeaderConfig())
    });

    try {
      const data = await axios.all(requests).then(axios.spread((response) => {
        return response
      }));
      if (data.data.erro)
        return { error: true, data: data.data }
      return data.data
    }
    catch (e) {
      return { error: true, data: e?.response?.data ? e.response.data : e }
    }
  }

  static async getSegments() {
    return await apiCall(`${Constants.getApiUrl()}/v1/segment`, 'get')
  }

  static async updateRessellerConsultant(resellerId, newConsultant) {
    return await apiCall(`${Constants.getApiUrl()}/v1/reseller/${resellerId}/change-consultant`, 'post', { consultant: newConsultant });
  }

  static async getOffices() {
    return await apiCall(`${Constants.getApiUrl()}/v1/office`, 'get')
  }

  static async newResellerContact(resellerId, contacts) {
    const url = `${Constants.getApiUrl()}/v1/reseller/contact`;
    const models = contacts.map(contact => {
      let contactModel = {
        name: contact.name,
        office: contact.office,
        reseller: resellerId
      }
      if (contact.office_open)
        contactModel.office_open = contact.office_open

      return contactModel
    });
    const requests = models.map(model => {
      return axios.post(url, model, Constants.getHeaderConfig())
    });
    try {
      const data = await axios.all(requests).then(axios.spread((...response) => {
        return response
      }));
      if (data.data)
        if (data.data.erro)
          return { error: true, data: data.data }

      await this.newContactEmailPhone(data, contacts);
      return data.data
    }
    catch (e) {
      return { error: true, data: e?.response?.data ? e.response.data : e }
    }
  }

  static async newContactEmailPhone(contactData, contacts) {
    try {
      const emailUrl = `${Constants.getApiUrl()}/v1/contact/email`;
      const phoneUrl = `${Constants.getApiUrl()}/v1/contact/phone`;

      const emailRequests = contacts.map((contato, i) => {
        const emailRequestsLvl2 = contato.emails.map(email => {
          return axios.post(emailUrl, { email: email.email, contact: contactData[i].data.contact.id }, Constants.getHeaderConfig())
        });
        return emailRequestsLvl2
      });
      const phoneRequests = contacts.map((contato, i) => {
        const phoneRequestsLvl2 = contato.phones.map(phone => {
          return axios.post(phoneUrl, { phone: phone.phone, contact: contactData[i].data.contact.id }, Constants.getHeaderConfig())
        });
        return phoneRequestsLvl2
      });

      const emailResponse = await axios.all(emailRequests[0]).then(axios.spread((response) => {
        return response;
      }));
      const phoneResponse = await axios.all(phoneRequests[0]).then(axios.spread((response) => {
        return response;
      }));

      if (emailResponse.data.erro || phoneResponse.data.erro)
        return { error: true, data: emailResponse.data.data }
      return (emailResponse.data, phoneResponse.data)
    }
    catch (e) {
      return { error: true, data: e?.response?.data ? e.response.data : e }
    }
  }
}
