import React, { useState } from 'react';
import { Button } from 'reactstrap';
import Exports from '../services/TablesExport';
const ExportButton = ({
	target,
	params,
	customClass
}) => {
	const [isLoading, setIsLoading] = useState(false);

	const requestData = () => {
		setIsLoading(true);
		const response = Exports[target](params && params)
		response.then(res => {
			if (!res.error) {
				let a = document.createElement('a');
				a.href = res.file;
				a.download = res.filename;
				a.click();
			}
			setIsLoading(false);
		})
	}

	return (
		<Button className={customClass} onClick={() => requestData()} disabled={isLoading} color='light'>
			{isLoading ? 'Exportando...' : 'Exportar'}
		</Button>
	)
}

function areEqual(prevProps, nextProps) {
	return (prevProps.params === nextProps.params)
}

// export default ExportButton;
export default React.memo(ExportButton, areEqual);