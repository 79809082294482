import { Cookies } from 'react-cookie';
import { apiUrlChecked } from '../functions/isOnProd'
export default class Constants {
  // static apiUrl = 'https://api.seagatecentral.com.br'
  // static apiUrl = isOnProd ? 'https://api.seagatecentral.com.br' : 'https://api-homolog.seagatecentral.com.br';
  static apiUrl = apiUrlChecked;
  // static apiUrl = 'http://127.0.0.1:8000'
  static getHeaderConfig() {
    const cookies = new Cookies();
    const user = cookies.get('user');
    const headerConfig = user ? { headers: { Authorization: `Bearer ${user.token}` } } : {}

    return headerConfig
  }

  static getApiUrl() {
    return this.apiUrl
  }
}