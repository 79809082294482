import React, { Component } from 'react'
import LeadsBranding from '../services/LeadsBrandingService'

const UserContext = React.createContext()

class UserProvider extends Component {
	state = {
		pendingData: {
			pendingBranding: 0,
			pendingLeads: 0
		}
	}

	setPendingData = () => {
		const response = LeadsBranding.getPending()

		response.then(data => {
			let pendingData = {
				pendingBranding: data.branding,
				pendingLeads: data.leads
			}
			this.setState({ pendingData })
		})
	}

	render() {
		const { children } = this.props
		const { pendingData } = this.state
		const { setPendingData } = this

		return (
			<UserContext.Provider
				value={{
					pendingData,
					setPendingData,
				}}
			>
				{children}
			</UserContext.Provider>
		)
	}
}

export default UserContext

export { UserProvider }