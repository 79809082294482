import React, { useState, useEffect } from 'react'
import Country from '../services/CountriesServices'
import { withTranslation } from 'react-i18next'
import { MultiSelect } from "react-multi-select-component";

const CountrySelect = ({ callBack, showEmptyOption, customName, customClass, isRequired, customId, customValue, t }) => {
	const [selectObj, setSelectState] = useState({
		data: [],
		isLoading: true,
		error: false,
	})
	const [selected, setSelected] = useState([]);

	const renderOptions = () => {
		if (selectObj.error)
			return (<option value="''">{t('error_ocurred')}</option>)

		let realData = []
		if (showEmptyOption)
			realData.push({ label: '', name: t('select_country') })

		selectObj.data.forEach(country => realData.push(country))

		// const mapped = realData.map((option, i) => {
		// 	return (
		// 		<option key={i} value={option.label}>{t(option.label === '' ? option.name : option.label)}</option>
		// 	)
		// })

		const mapped = realData.map((option, i) => {
			return {
				label: t(option.label === '' ? option.name : option.label),
				value: option.label
			}
		})

		return mapped

	}

	const handleResponse = response => {
		if (response.error) {
			setSelectState(prevState => { return { ...prevState, error: true } })
			return
		}

		setSelectState(prevState => { return { ...prevState, data: response } })
	}

	const fetchCountries = async () => {
		setSelectState(prevState => { return { ...prevState, isLoading: true } })
		const response = await Country.getCountries()
		setSelectState(prevState => { return { ...prevState, isLoading: false } })
		handleResponse(response)
	}

	useEffect(() => {
		fetchCountries()
	}, []);

	useEffect(() => {
		var countriesSelected = []

		selected.map(country => {
			return countriesSelected.push(country.value)
		})

		callBack({
			event: {
				target: {
					name: customName,
					value: countriesSelected.join()
				}
			}
		})
	}, [selected]);

	return (
		// <select
		// 	id={customId && customId}
		// 	onChange={callBack}
		// 	className={`bordered color-gray w-180p p-1 pointer select-model ${customClass || ''}`}
		// 	disabled={selectObj.isLoading || selectObj.error}
		// 	name={customName}
		// 	required={isRequired}
		// 	value={customValue && customValue}
		// >
		// 	{selectObj.isLoading ?
		// 		<option>{t('Loading data...')}</option>
		// 		:
		// 		renderOptions()
		// 	}
		// </select>
		<div style={{width: '200px', height: '36px', zIndex: 9}} className={`${customClass || ''}`}>
			<MultiSelect
				options={renderOptions()}
				value={selected}
				onChange={setSelected}
				className='w-100 h-100'
				overrideStrings={{
					selectSomeItems: "Selecione o país",
					allItemsAreSelected: "Todos os países foram selecionados",
					selectAll: "Todos",
					search: "Buscar",
				}}
				disableSearch
			/>
		</div>
	)
}

export default withTranslation()(CountrySelect)