import React from 'react'
import { Col, Button, DropdownToggle, DropdownMenu, Row, UncontrolledButtonDropdown } from 'reactstrap';
import { withTranslation } from 'react-i18next'
import Dates from '../services/DatesService'
import Revendas from '../services/ResellersService'
import Consultants from '../services/ConsultantsService'
import toPageOptions from '../functions/toPageOptions'
import SelectObject from '../models/SelectObjectModel'
import ConfigIcon from '../assets/svg/Config'
import CountrySelect from './CountrySelect';
import sortAsc from '../functions/sortAsc';
import RangeDatePicker from './RangeDatePicker';
import YearSelect from './YearSelect';
import quarters from '../constants/quarters';
import ExportButton from './ExportButton';
import filterCurrentQuarter from '../functions/filterCurrentQuarter';
import allCountries from '../constants/allCountriesList'
import DatePicker from 'react-datepicker'
import { format } from 'date-fns'
import pt from 'date-fns/locale/pt-BR'

import FIlterDates from './FIlterDates'

var date = new Date();
var today = new Date(date.getFullYear(), date.getMonth(), 1);
var lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

const getDateEnd = () => {
	var date = new Date();
	var lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
	return lastDayOfMonth.toISOString().split('T')[0]
}

const getDateInit = () => {
	const date = new Date();
	const month = date.getMonth() + 1;
	const day = date.getDate();
	const year = date.getFullYear();

	if (month < 10) {
		return `${year}-0${month}-01`;
	} else {
		return `${year}-${month}-01`;
	}
}
const getDateNow = () => {
	//getDateNow

	var date = new Date();

	var today = new Date(date.getFullYear(), date.getMonth(), date.getDate());
	return today.toISOString().split('T')[0]
}
class PageOptions extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			startDate: this.props.cleanDates ? '' : today,
			endDate: this.props.cleanDates ? '' : lastDayOfMonth,
			selectCountry: props.selectCountry,
			selects: [
				new SelectObject(
					'id',
					false,
					[],
					false,
					(data) => this.setConsultants(data),
					this.props.hideConsultants,
					(data) => props.fixedConsultants ? Consultants.getFilteredConsultant(allCountries) : Consultants.getFilteredConsultant(
						data ?
							data
							:
							props.selectCountry ? 'Brasil' : ''
					)
				),
				new SelectObject(
					'segment',
					false,
					[{ id: '', value: '', name: 'Selecione um segmento' }],
					false,
					(data) => this.setSegments(data),
					this.props.hideSegments,
					() => Revendas.getSegments()
				),

				new SelectObject(
					'questionnaireType',
					false,
					[
						{ id: 'all_questionnaries', value: 'all', name: 'Padrão' },
						{ id: 'systems', value: 15, name: 'EDS' }
					],
					false,
					// (type) => this.setQuestionnaireType(type),
					// !this.props.showQuestionnaireType,
					// () => Revendas.getQuestionnaireType()

					undefined,
					!this.props.showQuestionnaireType,
					undefined
				),

				new SelectObject(
					'tier',
					false,
					[
						{ id: '', value: '', name: this.props.t('select_tier') },
						{ id: 1, value: 1, name: '1' },
						{ id: 2, value: 2, name: '2' },
						{ id: 3, value: 3, name: '3' },
					],
					false,
					undefined,
					this.props.hideTiers,
					undefined
				),

				new SelectObject(
					'status',
					false,
					[
						{ id:'', name: 'Selecione um status', value: '' },
						{ id: 'pending', name: 'Pendente', value: '0' },
						{ id: 'schedule', name: 'Agendada', value: '1' },
						{ id: 'complete', name: 'Concluída', value: '2' },
						{ id: 'missed', name: 'Não realizada', value: '3' },
						{ id: 'cancelled', name: 'Cancelada', value: '4' },
					],
					false,
					undefined,
					this.props.hideStatus,
					undefined
				),

				new SelectObject(
					'statusTwo',
					false,
					[
						{ id:'', name: 'Selecione um status', value: '' },
						{ id: 'ativa', name: 'Ativa', value: 'ativa' },
						{ id: 'inativa', name: 'Inativa', value: 'inativa' },
					],
					false,
					undefined,
					this.props.hideStatusTwo,
					undefined
				),

				new SelectObject(
					'date',
					false,
					[{ id: '', value: '', name: 'Selecione uma data' }],
					false,
					(data) => this.setDates(data),
					this.props.hideDates,
					() => Dates.getDates()
				),
				new SelectObject(
					'year',
					false,
					[{ id: '', value: '', name: 'Selecione um ano' }],
					false,
					(data) => this.setYears(data),
					!this.props.showYears,
					() => Dates.getDates()
				),
				new SelectObject(
					'quarter',
					false,
					[],
					false,
					(data) => this.setQuarters(data),
					!this.props.showQuarters,
					() => Dates.getDates()
				),
			],
			quarterOptions: undefined,
			// isMobile: this.verifyDevice(),
			isMobile: false,
			disableFilterBtn: true,
			multiCountries: ''
		}


		this.multiCountries = this.multiCountries.bind(this)
		this.tglRequesting = this.tglRequesting.bind(this)
		this.handleChange = this.handleChange.bind(this)
		this.renderMobileSelects = this.renderMobileSelects.bind(this)


	}





	renderOptions(values, error) {
		if (error)
			return (<option value="">Ocorreu um erro</option>)

		if (values.length === 0 || !values)
			return (<option value="">{'Loading data...'}</option>)

		const mapped = values.map((option, i) => {
			return (
				<option key={i} value={option.value}>{option.name}</option>
			)
		})

		return mapped
	}

	verifyDevice() {
		const width = window.innerWidth;
		if (width < 1200)
			return true
		return false
	}

	async handleChange(e, name, label) {
		const value = e?.target?.value || ''
		const consultantsSelect = this.state.selects.find(select => select.isConsultants())

		if (name === 'country') {
			this.tglRequesting([consultantsSelect])
			const newConsultants = consultantsSelect.request(value);
			newConsultants.then(data => {
				// data = data.sort((a, b) => sortAsc(a, b, 'short_name'))
				if (this.props.selectedConsultant)
					this.props.valuesChange(data[0].id, value)

				this.tglRequesting([consultantsSelect])
				let processedData = this.handleApiData(data)
				consultantsSelect.setFunction(processedData)
			})
		}

		if (this.props.selectedConsultant) {
			if (name !== 'country')
				this.props.selectChange(e, name)
		}
		else if (name === 'year') {
			console.log("year", e.target.value)
			localStorage.setItem('year', e.target.value)
		}
		else if (name === 'id') {
			console.log("id", e.target.value)
			this.props.selectChange(e, name)
			localStorage.setItem('consultant_id', e.target.value)
		}
		else {
			if (name === 'quarter') {
				this.props.selectChange({ target: e }, name, label)
			}
			else
				this.props.selectChange(e, name)
			console.log(e.target.value, name)
			localStorage.setItem(name, e.target.value)
		}
		this.state.disableFilterBtn && this.setState({ disableFilterBtn: false })
	}



	renderSelects() {
		const mapped = this.state.selects.map((select, i) => {
			if (!select.cancelRule) {
				if (['date', 'year'].includes(select.name))
					return (
						<div key={i} className="">


							{(window.location.pathname === '/goals/overview' || window.location.pathname === '/resales/list') ?
								(<>
									<Row className="m-0">
										<Col xs="auto" className="p-0">
											<div className="w-100 ml-1">
												<DatePicker
													placeholderText='De'
													className="w-180p p-1 pointer select-model bordered color-gray"
													selected={this.state.startDate}
													onChange={(date) => [
														this.props.selectChange(format(date, 'dd-MM-yyyy'), 'to'),
														this.props.change(),
														this.setState({startDate: date})
													]}
													selectsStart
													dateFormat="dd/MM/yyyy"
													locale={pt}
												/>
											</div>
										</Col>
										<Col xs="auto" className="p-0">
											<div className="w-100 ml-1">
												<DatePicker
													placeholderText='Até'
													className="w-180p p-1 pointer select-model bordered color-gray"
													selected={this.state.endDate}
													onChange={(date) => [
														this.props.selectChange(format(date, 'dd-MM-yyyy'), 'from'),
														this.props.change(),
														this.setState({endDate: date})
													]}
													selectsStart
													dateFormat="dd/MM/yyyy"
													locale={pt}
												/>
											</div>
										</Col>
									</Row>

								</>)

								:

								(window.location.pathname === '/dashboard') ?
									(<>
										<div>

											<label>
												<span className="date-picker-start-end ml-2">Ínicio</span>
												<input type="date"
													className="w-180p p-1 pointer select-model ml-1 bordered color-gray"
													onChange={(e) => [this.handleChange(e, 'from'), this.props.change()]}
													placeholder="Enter a date"
													defaultValue={`${`${new Date().getFullYear()}-01-01`}`}
												/>
											</label>

											<label>
												<span className="date-picker-start-end ml-2">Fim</span>
												<input type="date"
													className="w-180p p-1 pointer select-model ml-1 bordered color-gray"
													onChange={(e) => [this.handleChange(e, 'to'), this.props.change()]}
													placeholder="Enter a date 2"
													defaultValue={getDateNow()}
												/>
											</label>
										</div>

									</>)
									:

									(<>
										<div className="select-withBtn">

											<label>
												<select
													disabled={select.requesting || select.error}
													onChange={(e) => [this.handleChange(e, 'date'), this.props.change()]}
													className="w-180p p-1 pointer select-model ml-1 bordered color-gray"
												>
													{select.requesting ?
														<option value="">{this.props.t('Loading data...')}</option>
														:
														this.renderOptions(select.data, select.error)
													}
												</select>
											</label>
										</div>

									</>)
							}
						</div>
					)
				else if (select.name === 'quarter')
					return (
						<RangeDatePicker
							hideCurrentMonth={this.props.hideCurrentMonth}
							hideCalendar={this.props.hideCalendar}
							onChange={(value, label) => [this.handleChange(value, 'quarter', label), this.props.change()]}
							options={this.state.quarterOptions}
							getCompleteQuarter={this.props.getCompleteQuarter}
						/>
					)

				return (
					<select
						onChange={(e) => [this.handleChange(e, select.name), this.props.change()]}
						key={i}
						disabled={select.requesting || select.error}
						className='bordered color-gray w-180p p-1 pointer select-model ml-1 teste'>
						{select.requesting && !this.props.fixedConsultants ?
							<option value="">{this.props.t('Loading data...')}</option>
							:
							this.renderOptions(select.data, select.error)
						}
					</select>
				)
			}

			return <React.Fragment key={i}></React.Fragment>
		})

		return mapped
	}

	renderMobileSelects() {
		const selectsTitles = {
			'country': 'Praça',
			'id': 'Consultor',
			'segment': 'Segmento',
			'tier': 'Tier',
			'status': 'Status',
			'date': 'Datas'
		}

		const mapped = this.state.selects.map((select, i) => {
			if (!select.cancelRule) {
				if (select.name === 'date')
					return (
						<Row key={i}>
							<Col>
								<h5>{selectsTitles[select.name]}</h5>
								<div
									className="select-withBtn">
									<label className="w-100">
										<select
											disabled={select.requesting || select.error}
											onChange={(e) => [this.handleChange(e, 'date'), this.props.change()]}
											value={select.value}
											className="w-100 p-1 pointer bordered select-modelbordered color-gray"
										>
											{select.requesting ?
												<option value="">{this.props.t('Loading data...')}</option>
												:
												this.renderOptions(select.data, select.error)
											}
										</select>
									</label>
								</div>
							</Col>
						</Row>
					)

				return (
					<Row key={i}>
						<Col>
							<h5>{selectsTitles[select.name]}</h5>
							<select
								onChange={(e) => [this.handleChange(e, select.name), this.props.change()]}
								disabled={select.requesting || select.error}
								value={select.value}
								className='bordered color-gray w-100 p-1 pointer select-model'>
								{select.requesting ?
									<option value="">{this.props.t('Loading data...')}</option>
									:
									this.renderOptions(select.data, select.error)
								}
							</select>
						</Col>
					</Row>
				)
			}

			return <React.Fragment key={i}></React.Fragment>
		})

		return mapped
	}

	multiCountries(params) {
		this.handleChange(params, 'country')
		
		this.props.change()
	}

	MainRender() {
		return (
			<div className="page-title-box">
				<div className={`${this.props.positionRight ? "page-title-right" : "text-sm-right"} d-flex align-items-start justify-content-end`}>

					{this.state.isMobile ?
						< UncontrolledButtonDropdown direction="left" className="ml-1 dropdown-modal">
							<DropdownToggle color="secondary" className="main-green-bg" caret>
								<ConfigIcon />
							</DropdownToggle>
							<DropdownMenu>
								<Row>
									<Col >
										<h4>Filtros</h4>
									</Col>
								</Row>

								{!this.props.hideCountry &&
									<CountrySelect
										customClass="w-100"
										// showEmptyOption={!this.state.selectCountry}
										callBack={this.multiCountries}
									/>
								}

								{this.renderMobileSelects()}
							</DropdownMenu>
							<Button disabled={this.state.disableFilterBtn} onClick={() => this.props.requestNewValues()} className='bg-primary ml-2 shadow-none'>
								{this.props.t('filter')}
							</Button>
						</UncontrolledButtonDropdown>
						:
						<React.Fragment>
							{!this.props.hideCountry &&
								<CountrySelect
									// showEmptyOption={!this.state.selectCountry}
									callBack={(e) => [this.handleChange(e, 'country'), this.props.change()]}
								/>
							}
							{this.renderSelects()}

							{window.location.pathname === '/profitability/results' ?
								(<>
									<YearSelect callBackOnChange={(e) => this.handleChange(e, 'year')} />

								</>
								) : (<></>)}






							{this.props.showExportBtn &&
								<div className='ml-2'>
									<ExportButton params={this.props.exportParams} customClass='bg-primary' target={this.props.exportTarget} />
								</div>
							}

							<Button onClick={() => this.props.requestNewValues()} className='bg-primary ml-2 shadow-none'>
								{this.props.t('filter')}
							</Button>
						</React.Fragment>
					}
				</div>
			</div >
		)
	}

	tglRequesting(selectsRequesting) {
		let currentSelects = this.state.selects
		const names = selectsRequesting.map(select => select.name)

		currentSelects.forEach(select => {
			if (names.includes(select.name))
				select.requesting = !select.requesting
		})

		this.setState({ selects: currentSelects })
	}

	handleApiData(data) {
		if (data?.error)
			return undefined

		else
			return data
	}

	getQuarterDate(date) {
		const newDate = date.substr(0, 2) + '-01-' + date.substr(3)
		return newDate
	}

	sortMonthsArray(months) {
		const sorted = months.sort((a, b) => {
			const aDate = this.getQuarterDate(a)
			const bDate = this.getQuarterDate(b)
			return new Date(aDate) - new Date(bDate)
		})

		return sorted
	}

	sortQuarters(quarters) {
		const mappedQuarters = quarters.map(quarter => {
			if (quarter.months.length > 0) {
				quarter.months = this.sortMonthsArray(quarter.months)
			}
			return quarter
		})

		return mappedQuarters
	}

	getQuarterYear(quarter) {
		if (['Q1', 'Q2'].includes(quarter.title))
			return (parseInt(quarter.months[0].substr(3)) + 1)
		return quarter.months[0].substr(3)
	}

	getQuarters(data) {
		for (let i = 0; i < data.length; i++) {
			for (let j = 0; j < quarters.length; j++) {
				const monthIndex = parseInt(data[i].ref.substr(0, 2))
				if (quarters[j].rules.includes(monthIndex))
					quarters[j].months.push(data[i].ref)
			}
		}
		let filteredQuartes = []
		let newQuarters = []

		newQuarters = this.sortQuarters(quarters)
		newQuarters.forEach(quarter => {
			if (quarter.months.length > 0) {
				const year = this.getQuarterYear(quarter)

				filteredQuartes.push({
					value: quarter.title,
					label: `${quarter.title}FY / ${year}`,
					from: this.getQuarterDate(quarter.months[0]),
					to: this.getQuarterDate(quarter.months[quarter.months.length - 1])
				})
			}
		})

		return filteredQuartes.reverse()
	}

	setConsultants(data) {
		// data = data.sort((a, b) => sortAsc(a, b, 'short_name'))
		const index = this.state.selects.indexOf(this.state.selects.find(e => e.name === 'id'))
		let currentSelects = this.state.selects
		if (!data) {
			currentSelects[index].error = true
			this.setState({ selects: currentSelects })

			return
		}
		currentSelects[index].data = toPageOptions.consultants(data, !this.props.selectedConsultant && this.props.t('select_consultant'))
		this.setState({ selects: currentSelects })
	}

	setSegments(data) {
		data = data.sort((a, b) => sortAsc(a, b, 'name'))
		const index = this.state.selects.indexOf(this.state.selects.find(e => e.name === 'segment'))
		let currentSelects = this.state.selects
		if (!data) {
			currentSelects[index].error = true
			this.setState({ selects: currentSelects })

			return
		}
		currentSelects[index].data = toPageOptions.segments(data, this.props.t('select_segment'))
		this.setState({ selects: currentSelects })
	}

	setDates(data) {
		const index = this.state.selects.indexOf(this.state.selects.find(e => e.name === 'date'))
		let currentSelects = this.state.selects
		if (!data) {
			currentSelects[index].error = true
			this.setState({ selects: currentSelects })

			return
		}
		currentSelects[index].data = toPageOptions.dates(data, this.props.noDateSelected)
		this.setState({ selects: currentSelects })
	}

	setYears(data) {
		const index = this.state.selects.indexOf(this.state.selects.find(e => e.name === 'year'))
		let currentSelects = this.state.selects
		if (!data) {
			currentSelects[index].error = true
			this.setState({ selects: currentSelects })

			return
		}
		const values = toPageOptions.years(data)
		this.props.caughtPeriod(values[0])
		currentSelects[index].data = values
		this.setState({ selects: currentSelects })
	}

	setQuarters(data) {
		let quarters = this.getQuarters(data)
		if (this.props.getCompleteQuarter)
			quarters = filterCurrentQuarter(quarters)
		if (this.props.hideCalendar)
			this.props.caughtPeriod(quarters[0])
		this.setState({
			quarterOptions: quarters
		})
	}

	async componentDidMount() {
		let requests = this.state.selects
			.filter(select => select.canMakeResquests())

		this.tglRequesting(requests)

		requests.forEach((req, i) => {
			const response = req.request()

			response.then(data => {
				if (['date', 'year'].includes(req.name)) {
					const dataPureArray = data.map(e => e.ref)
					data = this.sortMonthsArray(dataPureArray).map(e => { return { ref: e } })
					if (this.props.fiscalYears && req.name === 'year') {
						const currentMonth = new Date().getMonth() + 1
						const currentYear = new Date().getFullYear()
						if ([10, 11, 12].includes(currentMonth))
							data = [...data, { ref: `${currentMonth}-${currentYear + 1}` }]
					}
				}
				const processedData = this.handleApiData(data)

				requests[i].setFunction(processedData)

				if (i === requests.length - 1)
					this.tglRequesting(requests)

				if (this.props.selectedConsultant && req.isConsultants())
					this.props.selectConsultant(data[0].id)
			})
		})
	}

	render() {
		return (
			<Col id="page-options-row" className="mb-2" sm={this.props.fullWidth ? 12 : 8} >
				{!this.props.custom ? this.MainRender() :
					<FIlterDates
						requestNewValues={this.props.requestNewValues}
						selectChange={this.props.selectNewChange}
						selectCountry={this.props.selectCountry}
						handleChange={this.handleChange}
						renderOptions={this.renderOptions}
						setConsultants={this.setConsultants}
						selects={this.state.selects}
						showQuestionnaireType={this.props.showQuestionnaireType}
						position={this.props.position}
						showLATAM={this.props.showLATAM}
						hideQuarter={this.props.hideQuarter}
						hideCountry={this.props.hideCountry}
						order={this.props.order}
						showStatus={this.props.showStatus}
					/>
				}
			</Col >
		)
	}
}

export default withTranslation()(PageOptions)