export default value => {
	let modifiedValue = value;
	let replaceValue = value.includes('.000000Z') ? value.replace('.000000Z', '').split('T')[0] : value.split('T')[0];
	let dateFormat

	if (!modifiedValue.includes(' '))
		modifiedValue = modifiedValue.slice(0, 8) + '0' + modifiedValue.slice(8)

	if (new Date(modifiedValue) === 'Invalid Date')
		dateFormat = new Date(modifiedValue + 'T00:00:00')
	else
		dateFormat = new Date(replaceValue + 'T00:00:00')

	const rawMonth = dateFormat.getMonth() + 1
	const rawDay = dateFormat.getDate()

	const day = rawDay < 10 ? `0${rawDay}` : rawDay
	const month = rawMonth < 10 ? `0${rawMonth}` : rawMonth
	const year = dateFormat.getFullYear()

	const finalDate = [day, month, year].join('/')

	return finalDate
}