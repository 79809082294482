import quarters from "../constants/quarters";

export default (date = new Date()) => {
	let currentQuarter = {
		quarter: undefined,
		year: undefined,
		shortYear: undefined,
	}
	const yearsRules = {
		'Q1': new Date(date).getFullYear() + 1,
		'Q2': new Date(date).getFullYear() + 1,
		'Q3': new Date(date).getFullYear(),
		'Q4': new Date(date).getFullYear(),
	}
	const month = new Date(date).getMonth() + 1

	currentQuarter.quarter = quarters.find(quarter => {
		if (quarter.rules.includes(month))
			return quarter
	}).title

	currentQuarter.year = yearsRules[currentQuarter.quarter]
	currentQuarter.shortYear = parseInt(`${currentQuarter.year}`.substring(2))

	return currentQuarter
}