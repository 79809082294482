import Constants from './Constants'
import apiCall from './singleApiCallModel'
import getCountry from '../functions/getCountry'
import getParamsUrl from '../functions/getParamsUrl'
import allCountries from '../constants/allCountriesList'

export default class LeadsBranding {

  static getUrlParams(params) {
    const NoCountries = !params.country || params.country === allCountries
    params.country = NoCountries ? allCountries : params.country

    const controller = {
      country: 'countries',
      // date: 'date',
      month: 'month',
      year: 'year',
      quarter: 'quarter',
      id: 'consultant_id',
      sortField: 'sort_field',
      sortOrder: 'sort_order',
      per_page: 'limit',
      search: 'search',
      current_page: 'page',
      status: 'status',
      sortOrder: 'order_by',
      sortField: 'order',
    }

    return getParamsUrl(params, controller)
  }

  static async getBranding(filterOptions) {
    return await apiCall(`${Constants.getApiUrl()}/v1/admin/branding?${this.getUrlParams(filterOptions)}`, 'get')
  }

  static async getLeads(filterOptions) {
    return await apiCall(`${Constants.getApiUrl()}/v1/admin/leads?${this.getUrlParams(filterOptions)}`, 'get')
  }

  static async getPending() {
    return await apiCall(`${Constants.getApiUrl()}/v1/admin/check-leads-branding`, 'get')
  }

  static async setValidation(where, data) {
    where = where.replace('s', '')
    return await apiCall(`${Constants.getApiUrl()}/v1/${where}-save`, 'post', data)
  }
}