import React, { useState } from 'react'
import DatePicker from 'react-datepicker';
import filterCurrentQuarter from '../functions/filterCurrentQuarter';

const PredefinedOptions = (props) => {
	const {
		options,
		handleSelect,
		hideCurrentMonth,
		getCompleteQuarter
	} = props
	const isCalendar = props.dateOption === 'calendar'

	let mapped
	if (options)
		mapped = options?.map((option, i) => {
			return (
				<option key={i + option.value} value={option.value}>{option.value}</option>
				// <option key={i + option.value} value={option.value}>{option.label}</option>
			)
		})

	if (getCompleteQuarter)
		mapped = filterCurrentQuarter(mapped, true)

	return (
		<div className=" w-fit select-withBtn ">
			< label >
				<select
					disabled={!mapped ? true : false}
					className={`p-1 pointer select-model ml-1 bordered color-gray ${isCalendar ? 'w-30p show-arrow' : 'w-180p'}`}
					value={props.dateOption}
					onChange={e => handleSelect(e.target.value)}
				>
					{
						mapped ?
							hideCurrentMonth ?
								<></>
								:
								!getCompleteQuarter && <option value='currMonth'>Mês atual</option>
							:
							<option value='currMonth'>Carregando dados...</option>
					}
					{mapped && mapped}
				</select>
			</label >
		</div >
	)
}

const RangeDatePicker = props => {
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState();
	const [dateOption, setDateOption] = useState('')
	const [showCalendar, setShowCalendar] = useState(false);
	const calendarOption = { label: 'Especificar data', value: 'calendar', from: undefined, to: undefined }
	let options = props.options ? [...props.options] : undefined
	if (props.options) {
		if (!props.hideCalendar)
			options.push(calendarOption)
	}

	function handleCallBack(start, end) {
		const label = options.find(option => {
			const lastDayTo = new Date(new Date(option.to).getFullYear(), new Date(option.to).getMonth() + 1, 0).toLocaleDateString()
			return (new Date(option.from).toLocaleDateString() === start.toLocaleDateString()) && (lastDayTo === end.toLocaleDateString())
		})?.label || ''
		props.onChange({ target: { value: { from: start, to: end } } }, label)
	}

	function handleSelect(value) {
		setDateOption(value)
		if (value !== calendarOption.value) {
			setShowCalendar(false)

			let filteredQuarter

			if (value === 'currMonth') {
				let currDate = new Date()
				let currStartDate = new Date(currDate.getFullYear(), currDate.getMonth(), 1)
				filteredQuarter = { from: currStartDate, to: currDate }
			}
			else
				filteredQuarter = options.find(quarter => {
					if (quarter.value === value)
						return quarter
				})

			var date = new Date(filteredQuarter.to);
			var newEndDate

			if (value === 'currMonth')
				newEndDate = filteredQuarter.to
			else
				newEndDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

			setStartDate(new Date(filteredQuarter.from))
			setEndDate(newEndDate)

			handleCallBack(new Date(filteredQuarter.from), newEndDate)
		}
		else
			setShowCalendar(true)
	}

	function handleCalendarChange(date, i) {
		if (i === 0) {
			setStartDate(date);
			if (date && endDate)
				handleCallBack(date, endDate)
		}
		else {
			setEndDate(date);
			if (startDate && date)
				handleCallBack(startDate, date)
		}
	}

	return (
		<>
			{showCalendar &&
				<div className="d-flex">
					<div className="mr-2 ml-2">
						<DatePicker
							className="form-control"
							selected={startDate}
							onChange={(date) => handleCalendarChange(date, 0)}
							selectsStart
							startDate={startDate}
							endDate={endDate}
							dateFormat="dd/MM/yyyy"
							locale="pt-BR"
						/>
					</div>

					<div>
						<DatePicker
							className="form-control"
							placeholderText="Selecione a data final"
							selected={endDate}
							onChange={(date) => handleCalendarChange(date, 1)}
							selectsEnd
							startDate={startDate}
							endDate={endDate}
							minDate={startDate}
							dateFormat="dd/MM/yyyy"
							locale="pt-BR"
						/>
					</div>
				</div>
			}
			<PredefinedOptions
				handleSelect={handleSelect}
				options={options}
				getCompleteQuarter={props.getCompleteQuarter}
				hideCurrentMonth={props.hideCurrentMonth}
				dateOption={dateOption}
			/>
		</>
	);
};

function areEqual(prevProps, nextProps) {
	return (prevProps.options === nextProps.options)
}

export default RangeDatePicker
// export default React.memo(RangeDatePicker, areEqual)