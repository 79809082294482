import React from 'react'

const DownloadIcon = props => {
	return (
		<svg className={props.customClass} width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M19 13.6973V17.6973C19 18.2278 18.7893 18.7365 18.4142 19.1116C18.0391 19.4866 17.5304 19.6973 17 19.6973H3C2.46957 19.6973 1.96086 19.4866 1.58579 19.1116C1.21071 18.7365 1 18.2278 1 17.6973V13.6973M5 8.69727L10 13.6973M10 13.6973L15 8.69727M10 13.6973V1.69727" stroke="#6C757D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	)
}

export default DownloadIcon

