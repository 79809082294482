import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils';
import ImportDetails from '../pages/profitability/ImportDetails';
import Uploads from '../pages/profitability/Uploads';

const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
const Dashboard = React.lazy(() => import('../pages/dashboard'));
const Goals = React.lazy(() => import('../pages/Goals'));
const Products = React.lazy(() => import('../pages/profitability/Products'));
const Imports = React.lazy(() => import('../pages/profitability/Imports'));
const Exports = React.lazy(() => import('../pages/profitability/Exports'));
const Results = React.lazy(() => import('../pages/profitability/Results'));
const Analises = React.lazy(() => import('../pages/profitability/Analises'));
const Performance = React.lazy(() => import('../pages/Performance'));
const Tracker = React.lazy(() => import('../pages/PerformanceTracker'));
const NewConsultant = React.lazy(() => import('../pages/consultants/NewConsultant'));
const QuestData = React.lazy(() => import('../pages/questionnaires/Index'));
const QuestDetails = React.lazy(() => import('../pages/questionnaires/Details'));
const ActionsList = React.lazy(() => import('../pages/questionnaires/ActionsList'));
const AdminPage = React.lazy(() => import('../pages/admin/Admin'));
const Quantity = React.lazy(() => import('../pages/sales/Quantity'));
const Recipe = React.lazy(() => import('../pages/sales/Recipe'));
const Comparative = React.lazy(() => import('../pages/sales/Comparative'));
const VisualizarRevendas = React.lazy(() => import('../pages/resales/Resales'))
const NovaRevenda = React.lazy(() => import('../pages/resales/NewResale'))
const Consultores = React.lazy(() => import('../pages/consultants/Consultants'));
const Profile = React.lazy(() => import('../pages/consultants/Profile'));
const Branding = React.lazy(() => import('../pages/questionnaires/Branding'));
const Leads = React.lazy(() => import('../pages/questionnaires/Leads'));
const Schedules = React.lazy(() => import('../pages/schedules'));




const Accordions = React.lazy(() => import('../pages/template-original/uikit/Accordions'));
const Alerts = React.lazy(() => import('../pages/template-original/uikit/Alerts'));
const Badges = React.lazy(() => import('../pages/template-original/uikit/Badges'));
const Buttons = React.lazy(() => import('../pages/template-original/uikit/Buttons'));
const Cards = React.lazy(() => import('../pages/template-original/uikit/Cards'));
const Carousel = React.lazy(() => import('../pages/template-original/uikit/Carousel'));
const Dropdowns = React.lazy(() => import('../pages/template-original/uikit/Dropdowns'));
const ListGroups = React.lazy(() => import('../pages/template-original/uikit/ListGroups'));
const Modals = React.lazy(() => import('../pages/template-original/uikit/Modals'));
const Tabs = React.lazy(() => import('../pages/template-original/uikit/Tabs'));
const Toasts = React.lazy(() => import('../pages/template-original/uikit/Toasts'));
const Grid = React.lazy(() => import('../pages/template-original/uikit/Grid'));
const Popovers = React.lazy(() => import('../pages/template-original/uikit/Popovers'));
const Progress = React.lazy(() => import('../pages/template-original/uikit/Progress'));
const Ribbons = React.lazy(() => import('../pages/template-original/uikit/Ribbons'));
const Tooltips = React.lazy(() => import('../pages/template-original/uikit/Tooltips'));
const Typography = React.lazy(() => import('../pages/template-original/uikit/Typography'));
const Spinners = React.lazy(() => import('../pages/template-original/uikit/Spinners'));
const Widgets = React.lazy(() => import('../pages/template-original/uikit/Widgets'));
const DragDrop = React.lazy(() => import('../pages/template-original/uikit/DragDrop'));
const RangeSliders = React.lazy(() => import('../pages/template-original/uikit/RangeSliders'));
const Ratings = React.lazy(() => import('../pages/template-original/uikit/Ratings'));
const MDIIcons = React.lazy(() => import('../pages/template-original/uikit/MDIIcons'));
const Dripicons = React.lazy(() => import('../pages/template-original/uikit/Dripicons'));
const Unicons = React.lazy(() => import('../pages/template-original/uikit/Unicons'));
const BasicForms = React.lazy(() => import('../pages/template-original/forms/Basic'));
const FormValidation = React.lazy(() => import('../pages/template-original/forms/Validation'));
const FormAdvanced = React.lazy(() => import('../pages/template-original/forms/Advanced'));
const FormWizard = React.lazy(() => import('../pages/template-original/forms/Wizard'));
const FileUpload = React.lazy(() => import('../pages/template-original/forms/FileUpload'));
const Editors = React.lazy(() => import('../pages/template-original/forms/Editors'));
const ApexChart = React.lazy(() => import('../pages/template-original/charts/Apex'));
const BriteChart = React.lazy(() => import('../pages/template-original/charts/Brite'));
const ChartJs = React.lazy(() => import('../pages/template-original/charts/ChartJs'));
const BasicTables = React.lazy(() => import('../pages/template-original/tables/Basic'));
const AdvancedTables = React.lazy(() => import('../pages/template-original/tables/Advanced'));
const GoogleMaps = React.lazy(() => import('../pages/template-original/GoogleMaps'));

const PrivateRoute = ({ component: Component, roles, ...rest }) => (
	<Route
		{...rest}
		render={props => {
			if (!isUserAuthenticated()) {
				return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
			}

			const loggedInUser = getLoggedInUser();
			if (roles && roles.indexOf(loggedInUser.role) === -1) {
				return <Redirect to={{ pathname: '/' }} />;
			}

			return <Component {...props} />;
		}}
	/>
);

const rootRoute = {
	path: '/',
	exact: true,
	component: () => <Redirect to="/dashboard" />,
	route: PrivateRoute,
};

const dashboardRoutes = {
	name: 'Dashboard',
	icon: 'uil-graph',
	header: 'Navigation',
	path: '/dashboard',
	component: Dashboard,
	route: PrivateRoute,
};

const consultantRoutes = {
	path: '/consultant',
	name: 'Consultores',
	icon: 'uil-contact',
	component: Consultores,
	route: PrivateRoute,
};

const hidenRoutes = {
	path: '',
	path: '',
	name: 'Metas',
	component: Goals,
	route: PrivateRoute,
	hide: true,
	children: [
		{
			path: '/newconsultant',
			name: 'Novo consultor',
			component: NewConsultant,
			route: PrivateRoute,
			hide: true,
		},
		{
			path: '/pages/profile/:id',
			name: 'Profile',
			component: Profile,
			route: PrivateRoute,
			hide: true,
		},
		{
			path: '/newresale',
			name: 'Novo cliente',
			component: NovaRevenda,
			route: PrivateRoute,
			hide: true,
		},
	]
};

const goalsRoutes = {
	path: '/goals',
	icon: 'uil-goals',
	path: '/goals/overview',
	name: 'Metas',
	component: Goals,
	route: PrivateRoute,
};

const schedulesRoutes = {
	path: '/goals',
	icon: 'uil-calendar-alt',
	path: '/schedules',
	name: 'Ações',
	component: Schedules,
	route: PrivateRoute,
};

const profitabilityRoutes = {
	path: '/profitability',
	icon: 'uil-profitability',
	name: 'Rentabilidade',
	route: PrivateRoute,
	hide: true,
	children: [
		{
			path: '/profitability/products',
			name: 'Produtos',
			component: Products,
			route: PrivateRoute,
			hide: true,
		},
		{
			path: '/profitability/imports',
			name: 'Importações',
			component: Imports,
			route: PrivateRoute,
			hide: true,
		},
		{
			path: '/profitability/exports',
			name: 'Exportações',
			component: Exports,
			route: PrivateRoute,
			hide: true,
		},
		{
			path: '/profitability/details/:id',
			name: 'Detalhes da importação',
			component: ImportDetails,
			route: PrivateRoute,
			hide: true,
		},
		{
			path: '/profitability/results',
			name: 'Results',
			component: Results,
			route: PrivateRoute,
			hide: true,
		},
		{
			path: '/profitability/analises',
			name: 'Análise',
			component: Analises,
			route: PrivateRoute,
			hide: true,
		}
	]
};

const performanceRoutes = {
	path: '/performance',
	name: 'Performance',
	icon: 'uil-performance',
	hide: true,
	children: [
		{
			path: '/performance/tracker',
			name: 'Performance Tracker',
			component: Tracker,
			route: PrivateRoute,
			hide: true,
		},
		{
			path: '/performance/overview',
			name: 'Desempenho',
			component: Performance,
			route: PrivateRoute,
			hide: true,
		}
	]
};

const resalesRoutes = {
	path: '/resales',
	name: 'Clientes',
	icon: 'uil-case',
	component: VisualizarRevendas,
	route: PrivateRoute,
	children: [
		{
			path: '/resales/list',
			name: 'Listagem',
			component: VisualizarRevendas,
			route: PrivateRoute,
		},
		{
			path: '/resales/uploads',
			name: 'Importações',
			component: Uploads,
			route: PrivateRoute,
		},
	]
};

const questionnaireRoutes = {
	path: '/questionnaires',
	name: 'Questionários',
	icon: 'uil-quest',
	children: [
		// {
		// 	path: '/questionnaires/actions-list',
		// 	name: 'Lista de ações',
		// 	component: ActionsList,
		// 	route: PrivateRoute,
		// },
		{
			path: '/questionnaires/data/action',
			name: 'Dados dos questionários',
			component: QuestData,
			route: PrivateRoute,
		},
		{
			path: '/questionnaires/questionnaire-details/:id',
			name: 'Dados dos questionários',
			component: QuestDetails,
			route: PrivateRoute,
			hide: true
		},
		{
			path: '/questionnaires/branding',
			name: 'Branding',
			component: Branding,
			route: PrivateRoute,
		},
		{
			path: '/questionnaires/leads',
			name: 'Leads',
			component: Leads,
			route: PrivateRoute,
		}
	],
};

const uiRoutes = {
	path: '/ui',
	name: 'Components',
	icon: 'uil-package',
	header: 'UI Elements',
	children: [
		{
			path: '/ui/base',
			name: 'Base UI',
			children: [
				{
					path: '/ui/accordions',
					name: 'Accordions',
					component: Accordions,
					route: PrivateRoute,
				},
				{
					path: '/ui/alerts',
					name: 'Alerts',
					component: Alerts,
					route: PrivateRoute,
				},
				{
					path: '/ui/badges',
					name: 'Badges',
					component: Badges,
					route: PrivateRoute,
				},
				{
					path: '/ui/buttons',
					name: 'Buttons',
					component: Buttons,
					route: PrivateRoute,
				},
				{
					path: '/ui/cards',
					name: 'Cards',
					component: Cards,
					route: PrivateRoute,
				},
				{
					path: '/ui/carousel',
					name: 'Carousel',
					component: Carousel,
					route: PrivateRoute,
				},
				{
					path: '/ui/dropdowns',
					name: 'Dropdowns',
					component: Dropdowns,
					route: PrivateRoute,
				},
				{
					path: '/ui/grid',
					name: 'Grid',
					component: Grid,
					route: PrivateRoute,
				},
				{
					path: '/ui/listgroups',
					name: 'List Groups',
					component: ListGroups,
					route: PrivateRoute,
				},
				{
					path: '/ui/modals',
					name: 'Modals',
					component: Modals,
					route: PrivateRoute,
				},
				{
					path: '/ui/popovers',
					name: 'Popovers',
					component: Popovers,
					route: PrivateRoute,
				},
				{
					path: '/ui/progress',
					name: 'Progress',
					component: Progress,
					route: PrivateRoute,
				},
				{
					path: '/ui/ribbons',
					name: 'Ribbons',
					component: Ribbons,
					route: PrivateRoute,
				},
				{
					path: '/ui/spinners',
					name: 'Spinners',
					component: Spinners,
					route: PrivateRoute,
				},
				{
					path: '/ui/tabs',
					name: 'Tabs',
					component: Tabs,
					route: PrivateRoute,
				},
				{
					path: '/ui/toasts',
					name: 'Toasts',
					component: Toasts,
					route: PrivateRoute,
				},
				{
					path: '/ui/tooltips',
					name: 'Tooltips',
					component: Tooltips,
					route: PrivateRoute,
				},
				{
					path: '/ui/typography',
					name: 'Typography',
					component: Typography,
					route: PrivateRoute,
				},
				{
					path: '/ui/widgets',
					name: 'Widgets',
					component: Widgets,
					route: PrivateRoute,
				},
			],
		},
		{
			path: '/ui/extended',
			name: 'Extended UI',
			children: [
				{
					path: '/ui/dragdrop',
					name: 'Drag and Drop',
					component: DragDrop,
					route: PrivateRoute,
				},
				{
					path: '/ui/rangesliders',
					name: 'Range Sliders',
					component: RangeSliders,
					route: PrivateRoute,
				},
				{
					path: '/ui/ratings',
					name: 'Ratings',
					component: Ratings,
					route: PrivateRoute,
				},
			],
		},
		{
			path: '/ui/icons',
			name: 'Icons',
			children: [
				{
					path: '/ui/icons/dripicons',
					name: 'Dripicons',
					component: Dripicons,
					route: PrivateRoute,
				},
				{
					path: '/ui/icons/mdi',
					name: 'Material Design',
					component: MDIIcons,
					route: PrivateRoute,
				},
				{
					path: '/ui/icons/unicons',
					name: 'Unicons',
					component: Unicons,
					route: Unicons,
				},
			],
		},
		{
			path: '/ui/forms',
			name: 'Forms',
			children: [
				{
					path: '/ui/forms/basic',
					name: 'Basic Elements',
					component: BasicForms,
					route: PrivateRoute,
				},
				{
					path: '/ui/forms/advanced',
					name: 'Form Advanced',
					component: FormAdvanced,
					route: PrivateRoute,
				},
				{
					path: '/ui/forms/validation',
					name: 'Form Validation',
					component: FormValidation,
					route: PrivateRoute,
				},
				{
					path: '/ui/forms/wizard',
					name: 'Form Wizard',
					component: FormWizard,
					route: PrivateRoute,
				},
				{
					path: '/ui/forms/upload',
					name: 'File Upload',
					component: FileUpload,
					route: PrivateRoute,
				},
				{
					path: '/ui/forms/editors',
					name: 'Editors',
					component: Editors,
					route: PrivateRoute,
				},
			],
		},
		{
			path: '/ui/charts',
			name: 'Charts',
			children: [
				{
					path: '/ui/charts/apex',
					name: 'Apex',
					component: ApexChart,
					route: PrivateRoute,
				},
				{
					path: '/ui/charts/brite',
					name: 'Brite',
					component: BriteChart,
					route: PrivateRoute,
				},
				{
					path: '/ui/charts/chartjs',
					name: 'Chartjs',
					component: ChartJs,
					route: PrivateRoute,
				},
			],
		},
		{
			path: '/ui/tables',
			name: 'Tables',
			children: [
				{
					path: '/ui/tables/basic',
					name: 'Basic',
					component: BasicTables,
					route: PrivateRoute,
				},
				{
					path: '/ui/tables/advanced',
					name: 'Advanced',
					component: AdvancedTables,
					route: PrivateRoute,
				},
			],
		},
		{
			path: '/ui/googlemaps',
			name: 'Google Maps',
			component: GoogleMaps,
			route: PrivateRoute,
		},
	],
};

const authRoutes = {
	path: '/account',
	name: 'Auth',
	children: [
		{
			path: '/account/login',
			name: 'Login',
			component: Login,
			route: Route,
		},
		{
			path: '/account/logout',
			name: 'Logout',
			component: Logout,
			route: Route,
		},
		{
			path: '/account/register',
			name: 'Register',
			component: Register,
			route: Route,
		},
		{
			path: '/account/confirm',
			name: 'Confirm',
			component: Confirm,
			route: Route,
		},
		{
			path: '/account/forget-password',
			name: 'Forget Password',
			component: ForgetPassword,
			route: Route,
		},
	],
};

const flattenRoutes = routes => {
	let flatRoutes = [];

	routes = routes || [];
	routes.forEach(item => {
		flatRoutes.push(item);

		if (typeof item.children !== 'undefined') {
			flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
		}
	});
	return flatRoutes;
};

const allRoutes = [rootRoute, dashboardRoutes, authRoutes, consultantRoutes, resalesRoutes, goalsRoutes, schedulesRoutes, profitabilityRoutes, performanceRoutes, questionnaireRoutes, hidenRoutes];
const authProtectedRoutes = [dashboardRoutes, consultantRoutes, resalesRoutes, goalsRoutes, schedulesRoutes, profitabilityRoutes, performanceRoutes, questionnaireRoutes, hidenRoutes];
const allFlattenRoutes = flattenRoutes(allRoutes);
export { allRoutes, authProtectedRoutes, allFlattenRoutes };
