import React, { useEffect, useState } from 'react';
import CountrySelect from './CountrySelect';
import { withTranslation } from 'react-i18next'
import allCountries from '../constants/allCountriesList'

export default (props) => {
    var date = new Date();
    var today = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().split('T')[0];
    var lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().split('T')[0];

    const [option, setOption] = useState('current')
    const [current, setCurrent] = useState([today, lastDayOfMonth]);
    const [showQuarters, setShowQuarters] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const [calendarValue, setCalendarValue] = useState({
        month: '',
        year: ''
    });
    const [quarterValue, setQuarterValue] = useState({
        quarter: '',
        year: '',
    })
    const [questType, setQuestionType] = useState('all');
    const [status, setStatus] = useState('');
    const [countries, setCountries] = useState('');

    const filtered = (param) => {
        setOption(param)
        if (param === 'current') {
            setShowCalendar(false)
            setShowQuarters(false)
            setCalendarValue({
                month: '',
                year: ''
            });
            setQuarterValue({
                quarter: '',
                year: '',
            });
        }

        if (param === 'quarter') {
            setShowQuarters(true)
            setShowCalendar(false)
            setCalendarValue({
                month: '',
                year: ''
            });
        }

        if (param === 'calendar') {
            setShowCalendar(true)
            setShowQuarters(false)
            setQuarterValue({
                quarter: '',
                year: '',
            });
        }
    }

    useEffect(() => {
        if (calendarValue.month !== '' && calendarValue.year !== '') {
            props.selectChange(calendarValue.month, 'month')
            props.selectChange(calendarValue.year, 'year')
            props.selectChange('', 'quarter')
            props.selectChange('', 'from')
            props.selectChange('', 'to')
            props.selectChange(calendarValue.month + '-' + calendarValue.year, 'date')
        }
        if (quarterValue.quarter !== '' && quarterValue.year !== '') {
            props.selectChange(quarterValue.quarter, 'quarter')
            props.selectChange(quarterValue.year, 'year')
            props.selectChange('', 'month')
            props.selectChange('', 'from')
            props.selectChange('', 'to')
            props.selectChange(calendarValue.month + '-' + calendarValue.year, 'date')
        }
        if (!showQuarters && !showCalendar && option !== 'current') {
            props.selectChange(current[0], 'from')
            props.selectChange(current[1], 'to')
            props.selectChange('', 'quarter')
            props.selectChange('', 'month')
            props.selectChange('', 'year')
            props.selectChange('', 'date')
        }
        if (option === 'current') {
            props.selectChange('', 'from')
            props.selectChange('', 'to')
            props.selectChange('', 'quarter')
            props.selectChange(new Date().getMonth() + 1, 'month')
            props.selectChange(new Date().getFullYear(), 'year')
            props.selectChange('', 'date')
        }
        if(props.showQuestionnaireType) {
            props.selectChange(questType, 'questionnaireType')
        }
        if(!props.hideCountry) {
            props.selectChange(countries === '' ? allCountries : countries, 'country')
        }
        if(props.showStatus) {
            props.selectChange(status, 'status')
        }
    }, [current, calendarValue, quarterValue, questType, status, countries])

    const handleFiltered = () => {
        props.requestNewValues()
    }

    return (
        <div className={`d-flex flex-wrap col ${props.position === 'center' ? 'justify-content-center' : 'justify-content-end'} align-items-center pr-0`} style={{ columnGap: "0.75rem", rowGap: "0.75rem" }}>
            {!props.hideCountry &&
            <CountrySelect
                customClass={`w-min-120 ${props.order ? 'order-' + props.order.country : ''}`}
                showEmptyOption={props.showLATAM}
                callBack={setCountries}
            />
            }
            {props.selects && props.selects.map((select, i) => {
                if (['id'].includes(select.name)) {
                    return (
                        <select
                            onChange={(e) => [props.handleChange(e, 'id')]}
                            key={i}
                            className={`bordered color-gray w-min-120 p-1 pointer select-model ml-1 ${props.order ? 'order-' + props.order.consultant : ''}`}
                        >
                            {props.renderOptions(select.data, select.error)}
                        </select>
                    )
                }
            })}
            <div className='order-3 d-flex' style={{ columnGap: "0.75rem" }}>
                {props.showQuestionnaireType &&
                    <div className="row mx-0" style={{ columnGap: "0.75rem" }}>
                        <select
                            className="bordered color-gray w-min-120 p-1 pointer select-model"
                            value={questType}
                            onChange={e => setQuestionType(e.target.value)}
                        >
                            <option hidden>Tipo</option>
                            <option value="all">Todos</option>
                            <option value="default">Padrão</option>
                            <option value={15}>EDS</option>
                        </select>
                    </div>
                }
                {props.showStatus &&
                    <div className="row mx-0" style={{ columnGap: "0.75rem" }}>
                        <select
                            className="bordered color-gray w-min-120 p-1 pointer select-model"
                            value={status}
                            onChange={e => setStatus(e.target.value)}
                        >
                            <option value="" hidden>Selecione um status</option>
                            <option value="">Todas</option>
                            <option value="Pending" class="yellow">Pendente</option>
                            <option value="Schedule" class="blue">Agendada</option>
                            <option value="Complete" class="green">Concluída</option>
                            <option value="Missed" class="red">Não realizada</option>
                            <option value="Cancelled" class="gray">Cancelada</option>
                        </select>
                    </div>
                }
                {showQuarters &&
                    <div className="row mx-0" style={{ columnGap: "0.75rem" }}>
                        <select
                            className="bordered color-gray w-min-120 p-1 pointer select-model"
                            value={quarterValue.quarter}
                            onChange={e => setQuarterValue({
                                ...quarterValue,
                                quarter: e.target.value
                            })}
                        >
                            <option hidden>Quarter</option>
                            <option value="Q1">Q1</option>
                            <option value="Q2">Q2</option>
                            <option value="Q3">Q3</option>
                            <option value="Q4">Q4</option>
                        </select>
                        <select
                            className="bordered color-gray w-min-120 p-1 pointer select-model"
                            value={quarterValue.year}
                            onChange={e => setQuarterValue({
                                ...quarterValue,
                                year: e.target.value
                            })}
                        >
                            <option hidden>Ano Fiscal</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                        </select>
                    </div>
                }
                {showCalendar &&
                    <div className="row mx-0" style={{ columnGap: "0.75rem" }}>
                        <select
                            className="bordered color-gray w-min-120 p-1 pointer select-model"
                            value={calendarValue.month}
                            onChange={e => setCalendarValue({
                                ...calendarValue,
                                month: e.target.value
                            })}
                        >
                            <option hidden>Mês</option>
                            <option value="01">Janeiro</option>
                            <option value="02">Fevereiro</option>
                            <option value="03">Março</option>
                            <option value="04">Abril</option>
                            <option value="05">Maio</option>
                            <option value="06">Junho</option>
                            <option value="07">Julho</option>
                            <option value="08">Agosto</option>
                            <option value="09">Setembro</option>
                            <option value="10">Outubro</option>
                            <option value="11">Novembro</option>
                            <option value="12">Dezembro</option>
                        </select>
                        <select
                            className="bordered color-gray w-min-120 p-1 pointer select-model"
                            value={calendarValue.year}
                            onChange={e => setCalendarValue({
                                ...calendarValue,
                                year: e.target.value
                            })}
                        >
                            <option hidden>Ano</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                        </select>
                    </div>
                }
                <select className="bordered color-gray w-min-120 p-1 pointer select-model" value={option} onChange={e => filtered(e.target.value)}>
                    <option value="current">Mês atual</option>
                    {!props.hideQuarter && <option value="quarter">Quarter</option>}
                    <option value="calendar">Mensal</option>
                </select>
                <button onClick={() => handleFiltered()} className='bg-primary shadow-none px-3' style={{ height: '36px' }}>
                    filtrar
                </button>
            </div>
        </div>
    );
};