import Constants from './Constants'
import apiCall from './singleApiCallModel'
import countriesObject from '../constants/countriesObject'
import getParamsUrl from '../functions/getParamsUrl'

const defaultOptions = {
  "quarter": "Q2",
  "year": "2022",
  "country": ["BR", "CL", "CO", ]
}

export default class Profitability {
  static getUrlParams(filterOptions) {
    const control = {
      pageIndex: 'page',
      sortField: 'order',
      sortOrder: 'order_by',
      search: 'search',
      sizePerPage: 'limit',
      id: 'consultant_id',
      country: 'countries',
      date: 'date'
    }
    return getParamsUrl(filterOptions, control)
  }

  static async importProducts(products) {
    return await apiCall(`${Constants.getApiUrl()}/v1/admin/rentability/import-product-list`, 'post', products)
  }

  static async importPrices(products) {
    return await apiCall(`${Constants.getApiUrl()}/v1/admin/rentability/import-product-price`, 'post', products)
  }

  static async getProducts(filter) {
    return await apiCall(`${Constants.getApiUrl()}/v1/admin/rentability/product?${this.getUrlParams(filter)}`, 'get')
  }

  static async getProductDetails(stModel) {
    return await apiCall(`${Constants.getApiUrl()}/v1/admin/rentability/product/${stModel}`, 'get')
  }

  static async importProfitability(profitability) {
    return await apiCall(`${Constants.getApiUrl()}/v1/admin/rentability/upload`, 'post', profitability)
  }

  static async getImports(filter) {
    return await apiCall(`${Constants.getApiUrl()}/v1/admin/rentability/upload-report?${this.getUrlParams(filter)}`, 'get')
  }

  static async getImportDetails(id, filter) {
    return await apiCall(`${Constants.getApiUrl()}/v1/admin/rentability/upload-report/${id}?${this.getUrlParams(filter)}`, 'get')
  }

  static async getReports(filter) {
    return await apiCall(`${Constants.getApiUrl()}/v1/admin/rentability/list-report?${this.getUrlParams(filter)}`, 'get')
  }

  static async createReport(options) {
    return await apiCall(`${Constants.getApiUrl()}/v1/admin/rentability/create-report`, 'post', options)
  }

  static async importResellers(file) {
    return await apiCall(`${Constants.getApiUrl()}/v1/admin/resellers_to_import/import`, 'post', file)
  }

  static async listResellers() {
    return await apiCall(`${Constants.getApiUrl()}/v1/admin/resellers_to_import/list`, 'get')
  }

  static async segmentUnits(options = defaultOptions) {
    return await apiCall(`${Constants.getApiUrl()}/v1/admin/rentability/results/report-units-segment`, 'post', options)
  }

  static async segmentsMarketer(options = defaultOptions) {
    return await apiCall(`${Constants.getApiUrl()}/v1/admin/rentability/results/report-units-segment-marketer`, 'post', options)
  }

  static async segmentsUnitsCountry(options = defaultOptions) {
    return await apiCall(`${Constants.getApiUrl()}/v1/admin/rentability/results/report-units-segment-country`, 'post', options)
  }

  static async segmentsUnitsCountryMarketer(options = defaultOptions) {
    return await apiCall(`${Constants.getApiUrl()}/v1/admin/rentability/results/report-units-segment-marketer-country`, 'post', options)
  }




  static async TotalUnitsSegmentMarketerHV(options = defaultOptions) {
    return await apiCall(`${Constants.getApiUrl()}/v1/admin/rentability/results/report-units-segment-marketer-highvalue`, 'post', options)
  }
  static async TotalUnitsSegmentMarketerHVBr(options = defaultOptions) {
    return await apiCall(`${Constants.getApiUrl()}/v1/admin/rentability/results/report-units-segment-marketer-highvalue`, 'post', options)
  }
  static async TotalUnitsSegmentMarketerHVAr(options = defaultOptions) {
    return await apiCall(`${Constants.getApiUrl()}/v1/admin/rentability/results/report-units-segment-marketer-highvalue`, 'post', options)
  }
  static async TotalUnitsSegmentMarketerHVCl(options = defaultOptions) {
    return await apiCall(`${Constants.getApiUrl()}/v1/admin/rentability/results/report-units-segment-marketer-highvalue`, 'post', options)
  }
  static async TotalUnitsSegmentMarketerHVCo(options = defaultOptions) {
    return await apiCall(`${Constants.getApiUrl()}/v1/admin/rentability/results/report-units-segment-marketer-highvalue`, 'post', options)
  }
  static async TotalUnitsSegmentMarketerHVMx(options = defaultOptions) {
    return await apiCall(`${Constants.getApiUrl()}/v1/admin/rentability/results/report-units-segment-marketer-highvalue`, 'post', options)
  }
  static async TotalUnitsSegmentMarketerHVPe(options = defaultOptions) {
    return await apiCall(`${Constants.getApiUrl()}/v1/admin/rentability/results/report-units-segment-marketer-highvalue`, 'post', options)
  }

  



  static async totalUnitsSegmentMarketerHVByCountry(options = defaultOptions) {
    return await apiCall(`${Constants.getApiUrl()}/v1/admin/rentability/results/report-units-segment-marketer-highvalue-country`, 'post', options)
  }












  static async totalUnitsSegmentMarketerHVByCountryBr(options = defaultOptions) {
    return await apiCall(`${Constants.getApiUrl()}/v1/admin/rentability/results/report-units-segment-marketer-highvalue-country`, 'post', options)
  }

  static async totalUnitsSegmentMarketerHVByCountryAr(options = defaultOptions) {
    return await apiCall(`${Constants.getApiUrl()}/v1/admin/rentability/results/report-units-segment-marketer-highvalue-country`, 'post', options)
  }

  static async totalUnitsSegmentMarketerHVByCountryCl(options = defaultOptions) {
    return await apiCall(`${Constants.getApiUrl()}/v1/admin/rentability/results/report-units-segment-marketer-highvalue-country`, 'post', options)
  }

  static async totalUnitsSegmentMarketerHVByCountryCo(options = defaultOptions) {
    return await apiCall(`${Constants.getApiUrl()}/v1/admin/rentability/results/report-units-segment-marketer-highvalue-country`, 'post', options)
  }


  static async totalUnitsSegmentMarketerHVByCountryMx(options = defaultOptions) {
    return await apiCall(`${Constants.getApiUrl()}/v1/admin/rentability/results/report-units-segment-marketer-highvalue-country`, 'post', options)
  }

  static async totalUnitsSegmentMarketerHVByCountryPe(options = defaultOptions) {
    return await apiCall(`${Constants.getApiUrl()}/v1/admin/rentability/results/report-units-segment-marketer-highvalue-country`, 'post', options)
  }










  static async revenueAndUnits(options = defaultOptions) {
    return await apiCall(`${Constants.getApiUrl()}/v1/admin/rentability/results/report-revenue-units`, 'post', options)
  }

  static async customerBreadth(options = defaultOptions) {
    return await apiCall(`${Constants.getApiUrl()}/v1/admin/rentability/results/report-customer-breadth`, 'post', options)
  }


  static async analisesRevenueUnits(options = defaultOptions) {
    return await apiCall(`${Constants.getApiUrl()}/v1/admin/rentability/analysis/report-revenue-units`, 'post', options)
  }

  static async analisesCustomerBreadth(options = defaultOptions) {
    return await apiCall(`${Constants.getApiUrl()}/v1/admin/rentability/analysis/report-customer-breadth`, 'post', options)
  }

  static async unitsSegmentsMarketerHv(options = defaultOptions) {
    return await apiCall(`${Constants.getApiUrl()}/v1/admin/rentability/analysis/report-units-segment-marketer-highvalue`, 'post', options)
  }
}