import axios from "axios";
import Constants from '../services/Constants'

export function configureFakeBackend() {
	let users = [{ id: 1, username: 'test', password: 'test', firstName: 'Test', lastName: 'User', role: 'Admin' }];
	let realFetch = window.fetch;

	window.fetch = function (url, opts) {

		return new Promise((resolve, reject) => {

			const params = JSON.parse(opts.body);

			// login
			if (url.endsWith('/users/authenticate') && opts.method === 'POST') {
				const connection = async () => await axios.post(`${Constants.getApiUrl()}/auth/login`, {
					"email": params.username,
					"password": params.password
				})
					.then(res => {

						if (res.data && verifyLoginPermition(res.data.user.role.id)) {
							let responseJson = {
								id: res.data.id,
								username: res.data.name,
								role: res.data.user.role.name,
								token: res.data.access_token
							};

							localStorage.setItem('userData', JSON.stringify(res.data))
							resolve({ ok: true, json: () => responseJson });
						}
						else
							reject('Dados incorretos');
						return res.data.access_token;
					})
					.catch(error => {
						reject('Acesso negado');
					});

				connection()
				return;
			}

			// register
			if (url.endsWith('/users/register') && opts.method === 'POST') {
				// get parameters from post request
				let params = JSON.parse(opts.body);

				// add new users
				let { firstName, lastName } = params.fullname.split(' ');
				let newUser = {
					id: users.length + 1,
					username: firstName,
					password: params.password,
					firstName: firstName,
					lastName: lastName,
					role: 'Admin',
				};
				users.push({ newUser });

				let responseJson = {
					id: newUser.id,
					username: newUser.username,
					firstName: newUser.firstName,
					lastName: newUser.lastName,
					role: newUser.role,
					token:
						'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjb2RlcnRoZW1lcyIsImlhdCI6MTU4NzM1NjY0OSwiZXhwIjoxOTAyODg5NDQ5LCJhdWQiOiJjb2RlcnRoZW1lcy5jb20iLCJzdWIiOiJzdXBwb3J0QGNvZGVydGhlbWVzLmNvbSIsImxhc3ROYW1lIjoiVGVzdCIsIkVtYWlsIjoic3VwcG9ydEBjb2RlcnRoZW1lcy5jb20iLCJSb2xlIjoiQWRtaW4iLCJmaXJzdE5hbWUiOiJIeXBlciJ9.P27f7JNBF-vOaJFpkn-upfEh3zSprYfyhTOYhijykdI',
				};
				resolve({ ok: true, json: () => responseJson });
				return;
			}

			// forget password
			if (url.endsWith('/users/password-reset') && opts.method === 'POST') {

				const connection = async () => await axios.post(`${Constants.getApiUrl()}/password/create`, {
					"email": params.username,
				}).then(res => {
					if (res.data.error) {
						reject('E-mail não encontrado');
					}
					else {
						let responseJson = {
							message: res.data.message,
						};
						resolve({ ok: true, json: () => responseJson });
					}

				})
					.catch(error => {
						console.log(error);
						reject('E-mail não encontrado');
					})

				connection();
				return;
			}

			// get users
			if (url.endsWith('/users') && opts.method === 'GET') {
				// check for fake auth token in header and return users if valid, this security is implemented server side in a real application
				if (
					opts.headers &&
					opts.headers.Authorization ===
					'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjb2RlcnRoZW1lcyIsImlhdCI6MTU4NzM1NjY0OSwiZXhwIjoxOTAyODg5NDQ5LCJhdWQiOiJjb2RlcnRoZW1lcy5jb20iLCJzdWIiOiJzdXBwb3J0QGNvZGVydGhlbWVzLmNvbSIsImxhc3ROYW1lIjoiVGVzdCIsIkVtYWlsIjoic3VwcG9ydEBjb2RlcnRoZW1lcy5jb20iLCJSb2xlIjoiQWRtaW4iLCJmaXJzdE5hbWUiOiJIeXBlciJ9.P27f7JNBF-vOaJFpkn-upfEh3zSprYfyhTOYhijykdI'
				) {
					resolve({ ok: true, json: () => users });
				} else {
					// return 401 not authorised if token is null or invalid
					reject('Unauthorised');
				}

				return;
			}

			// pass through any requests not handled above
			realFetch(url, opts).then(response => resolve(response));
		});
	};
}

function verifyLoginPermition(roleId) {
	return roleId === 1
}