export default {
	'': undefined,
  'BR': "Brazil",
  'Brazil': "Brazil",
  'Brasil': "Brazil",
  'Brazil ': "Brazil",
  'Brasil ': "Brazil",

  'AR': "Argentina",
  'Argentina': "Argentina",
  'Argentina ': "Argentina",

  'CL': "Chile",
  'Chile': "Chile",
  'Chile ': "Chile",

  'CO': "Colombia",
  'Colombia': "Colombia",
  'Colômbia': "Colombia",
  'Colombia ': "Colombia",
  'Colômbia ': "Colombia",

  'MX': "Mexico",
  'Mexico': "Mexico",
  'México': "Mexico",
  'Mexico ': "Mexico",
  'México ': "Mexico",

  'PE': "Peru",
  'Peru': "Peru",
  'Peru ': "Peru",

  'UY': "Uruguay",
  'Uruguay': "Uruguay",
  'Uruguay ': "Uruguay",
  'Uruguai': "Uruguay",
  'Uruguai ': "Uruguay",

  'EC': "Ecuador",
  'Ecuador': "Ecuador",
  'Ecuador ': "Ecuador",
  'Equador': "Ecuador",
  'Equador ': "Ecuador",

  'GT': "Guatemala",
  'Guatemala': "Guatemala",
  'Guatemala ': "Guatemala",

  'PA': "Panama",
  'Panama': "Panama",
  'Panamá': "Panama",
  'Panama ': "Panama",
}