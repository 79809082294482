import Constants from '../services/Constants'
import apiCall from './singleApiCallModel'
import countriesObject from '../constants/countriesObject'

export default class Consultants {

  static async getConsultants(props) {
    return await apiCall(`${Constants.getApiUrl()}/v1/consultant`, 'get')
  }

  static async getConsultantData(id) {
    return await apiCall(`${Constants.getApiUrl()}/v1/consultant/${id}`, 'get')
  }

  static async delConsultant(id) {
    return await apiCall(`${Constants.getApiUrl()}/v1/consultant/${id}`, 'del')
  }

  static async newConsultant(consultor) {
    return await apiCall(`${Constants.getApiUrl()}/v1/consultant`, 'post', consultor)
  }

  static async updateConsultat(id, data, addressData) {
    const response = await apiCall(`${Constants.getApiUrl()}/v1/consultant/${id}`, 'put', data)
    const addressResponse = await this.updateConsultantAddress(id, addressData)
    const statusResponse = await this.updateConsultantStatus(id, data.status)

    if (response.error || addressResponse.error || statusResponse.error)
      if (addressResponse?.error) {
        console.log('erro no endereço')
        return addressResponse
      }
      else if (response?.data?.error) {
        console.log('erro nos dados do consultor ')
        return response
      }
      else if (statusResponse?.error) {
        console.log('erro no status do consultor ')
        return statusResponse
      }
  }

  static async updateConsultantAddress(id, addressData) {
    return await apiCall(`${Constants.getApiUrl()}/v1/consultant/${id}/address `, 'put', addressData)
  }

  static async updateConsultantStatus(id, status) {
    return await apiCall(`${Constants.getApiUrl()}/v1/consultant/${id}/status?status=${status} `, 'get')
  }

  static async getFilteredConsultant(country) {
    const caughtCountry = countriesObject[country]
    return await apiCall(`${Constants.getApiUrl()}/v1/consultants-geral${caughtCountry ? '?country=' + caughtCountry : ''}`, 'get')
  }
}