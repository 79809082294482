export default class SelectModel {
	constructor(name, requesting, data, error, setFunction, cancelRule, request, value) {
		this.name = name;
		this.requesting = requesting;
		this.data = data;
		this.error = error;
		this.setFunction = setFunction;
		this.cancelRule = cancelRule;
		this.request = request;
		this.value = value
	}

	canMakeResquests() {
		if (this.request && !this.cancelRule)
			return this
		return false
	}

	isConsultants() {
		if (this.name === 'id')
			return this
		return false
	}
}