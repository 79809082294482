export default [
	{
		text: '10',
		value: 10,
	},
	{
		text: '50',
		value: 50,
	},
	{
		text: '100',
		value: 100,
	},
]