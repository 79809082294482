function handleConsultants(value, label) {
	let realArray = []
	if (label)
		realArray.push({ id: 0, short_name: label })
	value.forEach(e => realArray.push(e))

	const mapped = realArray.map(e => {
		return { id: e.id, value: e.id, name: e.short_name, selected: e.selected }
	})

	return mapped
}

function handleDates(value, noDateSelected) {
	if (value.length === 0)
		return [{ id: 0, value: 0, name: 'Selecione uma data', selected: false }]

	const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
	let mapped = value.map((e, i) => {
		const realName = `${months[parseInt(e.ref.substr(0, 2)) - 1]} / ${e.ref.substr(3)}`
		return { id: i, value: e.ref, name: realName, selected: false }
	})
	mapped = mapped.reverse()

	if (noDateSelected)
		mapped = [{ id: 'i', value: '', name: 'Selecione um período', selected: true }, ...mapped]

	return mapped
}

function handleSegments(value, label) {
	let realArray = [{ id: 0, name: label }]
	value.forEach(e => realArray.push(e))
	const mapped = realArray.map(segment => {
		return { id: segment.id, value: segment.id, name: segment.name }
	})

	return mapped
}

function handleCountries(value, selectCountry) {
	let realArray = !selectCountry ? [{ id: 0, name: 'Selecione um país' }] : []
	value.forEach(e => realArray.push(e));
	const mapped = realArray.map(country => {
		return { id: country.id, value: country.name, name: country.name }
	})
	return mapped
}

function handleYears(value) {
	const years = value.map((date, i) => {
		return date.ref.substr(3)
	})
	const filteredYears = new Set(years)
	const mappedYears = Array.from(filteredYears).map((year, i) => {
		return { id: i, value: year, name: year, selected: false }
	})
	return Array.from(mappedYears).reverse()
}

const controller = {
	consultants: (value, label) => handleConsultants(value, label),
	dates: (value, noDateSelected) => handleDates(value, noDateSelected),
	years: value => handleYears(value),
	segments: (value, label) => handleSegments(value, label),
	countries: (value, isOnlyCountries) => handleCountries(value, isOnlyCountries),
}

export default controller