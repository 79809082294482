import axios from 'axios'
import Constants from '../services/Constants'
import isOnProd from '../functions/isOnProd'

const singleApiCall = async (url, method, bodyData = null) => {
  try {
    const methodsCall = {
      get: () => axios.get(url, Constants.getHeaderConfig()),
      post: () => axios.post(url, bodyData, Constants.getHeaderConfig()),
      del: () => axios.delete(url, Constants.getHeaderConfig()),
      put: () => axios.put(url, bodyData, Constants.getHeaderConfig()),
      patch: () => axios.patch(url, bodyData, Constants.getHeaderConfig()),
    }

    let data = await methodsCall[method]()

    if (data.error) {
      return { error: true, data: data.data }
    }
    if (!isOnProd) {
      console.log('Requisição: ', url)
      console.log('Resposta: ', data.data)
      console.log('')
    }
    return data.data
  }
  catch (e) {
    if (!isOnProd) {
      console.log('Requisição: ', url)
      console.log('Resposta: ', e)
      console.log('')
    }

    if (window.location.pathname === '/goals/overview' && e?.response?.data.message) {
      alert(e.response.data.message)
      // window.location.reload()
    }
    return { error: true, data: e?.response?.data ? e.response.data : e }
  }
}

export default singleApiCall