import React from 'react';
import Spinner from '../components/Spinner'


export default class DataSpinner extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <>
        {
          this.props.render &&
          <div className='loadingData'>
            <Spinner size='lg' className="spinner-border-lg mr-1" tag="span" color="success" />
          </div>
        }
      </>
    )
  }
}