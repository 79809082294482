import React from 'react'
import { withTranslation } from 'react-i18next'

const RenderingData = props => (
	<div>
		<b>
			{props.t('Loading data...')}
		</b>
	</div>
)

export default withTranslation()(RenderingData)