import Constants from '../services/Constants'
import apiCall from './singleApiCallModel'

export default class Exports {

	static async Resellers(params) {
		return await apiCall(`${Constants.getApiUrl()}/v1/admin/export/resellers?${params}`, 'get')
	}

	static async Consultants() {
		return await apiCall(`${Constants.getApiUrl()}/v1/admin/export/consultants`, 'get')
	}

	static async Actions(params) {
		return await apiCall(`${Constants.getApiUrl()}/v1/admin/export/questionnaires?${params}`, 'get')
	}
	static async PerformanceTracker(params) {
		return await apiCall(`${Constants.getApiUrl()}/v1/admin/export/performance-tracker?${params}`, 'get')
	}
}