import React from 'react'

const svg = () => {
	return (
		<svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M3.59091 16V10.1667M3.59091 6.83333V1M10.5 16V8.5M10.5 5.16667V1M17.4091 16V11.8333M17.4091 8.5V1M1 10.1667H6.18182M7.90909 5.16667H13.0909M14.8182 11.8333H20" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	)
}

export default svg