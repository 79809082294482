import React, { useState, useEffect } from 'react'
import Country from '../services/CountriesServices'
import { withTranslation } from 'react-i18next'

const YearSelect = ({ callBackOnChange, showEmptyOption, customName, customClass, customId, customValue, t }) => {
	const [selectObj, setSelectState] = useState({
		data: [],
		isLoading: true,
		error: false,
	})

	const renderOptions = () => {



			return (
                <>
                    <option value="2022">2022</option>
                    <option value="2021">2021</option>
                </>
			)
	
		

	}

	// const handleResponse = response => {
	// 	if (response.error) {
	// 		setSelectState(prevState => { return { ...prevState, error: true } })
	// 		return
	// 	}

	// 	setSelectState(prevState => { return { ...prevState, data: response } })
	// }

	// const fetchCountries = async () => {
	// 	setSelectState(prevState => { return { ...prevState, isLoading: true } })
	// 	const response = await Country.getCountries()
	// 	setSelectState(prevState => { return { ...prevState, isLoading: false } })
	// 	handleResponse(response)
	// }

	// useEffect(() => {
	// 	fetchCountries()
	// }, []);


	return (
		<select
			id={customId && customId}
			onChange={callBackOnChange}
			className={`selectYear bordered color-gray w-180p p-1 pointer select-model ${customClass || ''}`}
			
			name={customName}
			value={customValue && customValue}
		>
			{
				renderOptions()
			}
		</select>
	)
}

export default withTranslation()(YearSelect)