import getCurrentQuarter from "./getCurrentQuarter"

export default (quarter = [], isElement) => {


	const filtered = quarter.filter(q => {
		const currentQuarter = getCurrentQuarter()
		let quarterValues = {
			quarter: undefined,
			year: undefined
		}

		if (isElement) {
			quarterValues.quarter = q?.props?.value
			quarterValues.year = q?.props?.children.substr(7)
		}
		else {
			quarterValues.quarter = q?.value
			quarterValues.year = q?.label?.substr(7)
		}
		
		if (quarterValues.year < currentQuarter.year)
			return q
		if (quarterValues.quarter.substr(1) < currentQuarter.quarter.substr(1))
			return q
	})
	return filtered
}