// @flow
import React, { Component } from 'react';
import Routes from './routes/Routes';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

// setup fake backend
import { configureFakeBackend } from './helpers';

// Themes
import './assets/scss/Saas.scss';
import './assets/main.css'

import { UserProvider } from './context/pendingFiles'

// For Dark import Saas-Dark.scss
// import './assets/scss/Saas-Dark.scss';

// For Modern import Modern.scss
// import './assets/scss/Modern.scss';
// For modern dakr import Modern-Dark.scss
// import './assets/scss/Modern-Dark.scss';

// For Creative demo import Modern.scss
// import './assets/scss/Creative.scss';
// For Creative dark demo import Modern.scss
// import './assets/scss/Creative-Dark.scss';

// configure fake backend
configureFakeBackend();

/**
 * Main app component_
 */

class App extends Component {
	render() {
		return <UserProvider>
			<Routes></Routes>
		</UserProvider>
			;
	}
}

export default App;
