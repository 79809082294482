import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from './locales/translationEN.json'
import translationPT from './locales/translationPT.json'
import translationES from './locales/translationES.json'
const resources = {
  en: {
    translation: translationEN
  },
  pt: {
    translation: translationPT
  },
  es: {
    translation: translationES
  }
};
const localLang = JSON.parse(localStorage.getItem("currentLang"))?.letters || 'pt'

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: localLang,

    keySeparator: false,

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
